const SPLIT_PERC_CONTROL = 0.5; // 0.5 = 50% DME control and 50% Yield Manager control
const TEST_NAME = `aymABTest`; // cookie and param name
const DME_CONTROL = `dme`;
const AYM_CONTROL = `aym`;

export const runAYSplitTest = () => {
  if (FEATURES.ASSERTIVE_YIELD && window.sessionStorage) {
    let selectedGroup = window.sessionStorage.getItem(TEST_NAME);

    if (!selectedGroup) {
      selectedGroup = Math.random() < SPLIT_PERC_CONTROL ? DME_CONTROL : AYM_CONTROL;
      window.sessionStorage.setItem(TEST_NAME, selectedGroup);
    }
  }
};

export const isAYMEnabled = () =>
  FEATURES.ASSERTIVE_YIELD &&
  window.sessionStorage &&
  window.sessionStorage.getItem(TEST_NAME) === AYM_CONTROL;
