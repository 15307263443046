import createElement from '../../services/create-element';

export const initAdScripts = () => {
  const adScripts = window.Site.adScripts;

  adScripts.forEach(({ src, attributes }) => {
    const script = createElement(`script`, { src, ...attributes });

    document.head.appendChild(script);
  });
};
